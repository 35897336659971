import React, { useState } from 'react';
import {
    Button,
    TextField,
    Grid,
    Paper,
    Container,
    styled
} from '@mui/material';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
    backgroundColor: '#f0f0f0',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-input': {
        fontSize: '2rem',
        textAlign: 'right',
    },
    marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
    height: 60,
    fontSize: '1.5rem',
    borderRadius: theme.spacing(1),
}));

const EqualButton = styled(StyledButton)(({ theme }) => ({
    height: '100%',
    gridArea: '2 / 4 / 6 / 5',
    backgroundColor: '#a7cdff',
    '&:hover': {
        backgroundColor: '#86b9ff',
    },
}));

const Calculator = () => {
    const [input, setInput] = useState('');

    const handleClick = (value) => {
        if (value === '=') {
            try {
                setInput(eval(input).toString());
            } catch (error) {
                setInput('Error');
            }
        } else if (value === 'all-clear') {
            setInput('');
        } else {
            setInput(input + value);
        }
    };

    const buttonConfig = [
        { value: '+', color: 'primary' },
        { value: '-', color: 'primary' },
        { value: '*', display: '×', color: 'primary' },
        { value: '/', display: '÷', color: 'primary' },
        { value: '7', color: 'secondary' },
        { value: '8', color: 'secondary' },
        { value: '9', color: 'secondary' },
        { value: '4', color: 'secondary' },
        { value: '5', color: 'secondary' },
        { value: '6', color: 'secondary' },
        { value: '1', color: 'secondary' },
        { value: '2', color: 'secondary' },
        { value: '3', color: 'secondary' },
        { value: '0', color: 'secondary' },
        { value: '.', color: 'secondary' },
        { value: 'AC', color: 'error' },
      ];

    return (
        <Container maxWidth="xs">
            <StyledPaper elevation={0}>
                <StyledTextField
                    fullWidth
                    variant="outlined"
                    value={input}
                    disabled
                />
                <Grid container spacing={1}>
                    {buttonConfig.map((item, index) => {
                        if (index === 0 || index === 1 || index === 2 || index === 3) {
                            return (
                                <Grid item xs={3} key={item.value}>
                                    <StyledButton
                                        fullWidth
                                        variant="contained"
                                        color={item.color}
                                        onClick={() => handleClick(item.value === 'AC' ? 'all-clear' : item.value)}
                                        sx={{
                                            backgroundColor: item.color === 'primary' ? '#6e93f7' :
                                                item.color === 'secondary' ? '#ffffff' :
                                                    item.color === 'error' ? '#ff6961' : 'inherit',
                                            color: item.color === 'secondary' ? 'black' : 'white',
                                        }}
                                    >
                                        {item.display || item.value}
                                    </StyledButton>
                                </Grid>
                            )
                        }
                        else if (item.value === '=') {
                            <Grid item xs={3}
                                columns={3}
                            >
                                <EqualButton
                                    fullWidth
                                    variant="contained"
                                    onClick={() => handleClick('=')}
                                >
                                    =
                                </EqualButton>
                            </Grid>
                        }
                        else {
                            return (
                                <Grid item xs={3} key={item.value}>
                                    <StyledButton
                                        fullWidth
                                        variant="contained"
                                        color={item.color}
                                        onClick={() => handleClick(item.value === 'AC' ? 'all-clear' : item.value)}
                                        sx={{
                                            backgroundColor: item.color === 'primary' ? '#6e93f7' :
                                                item.color === 'secondary' ? '#ffffff' :
                                                    item.color === 'error' ? '#ff6961' : 'inherit',
                                            color: item.color === 'secondary' ? 'black' : 'white',
                                        }}
                                    >
                                        {item.display || item.value}
                                    </StyledButton>
                                </Grid>
                            )
                        }
                    })}

                </Grid>
            </StyledPaper>
        </Container>
    );
};

export default Calculator;