import { Box, FormControl, InputLabel, MenuItem, Select, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import axios from "axios";
import getBaseUrl from "../../services/BaseUrl";
import { toast } from "react-toastify";

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const [admins, setAdmins] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState({name:"Admin"});
  const [teamData,setTeamData]=useState([])

  const columns = [
    { 
      field: "id", 
      headerName: "S.No", 
      width: 70 
    },
    {
      field: "contactName",
      headerName: "Contact Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "emailAddress",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "contactType",
      headerName: "Contact Type",
      flex: 1,
      renderCell: ({ row: { contactType } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              contactType === "Staff"
                ? colors.greenAccent[600]
                : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            {contactType === "Staff" && <AdminPanelSettingsOutlinedIcon />}
            {contactType === "Client" && <LockOpenOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "7px" }}>
              {contactType}
            </Typography>
          </Box>
        );
      },
    },
  ];

  const getAdmins = async () => {
    // console.log(`${getBaseUrl()}/api/users`)
    try {
      const response = await axios.get(`${getBaseUrl()}/api/users`);
      if (response.data.success) {
        // toast.success("Admin Get Successfully")
        setAdmins(response.data.admins)
      }
      else {
        setAdmins([])
        toast.error(response.data.error)
      }
    } catch (error) {
      console.error("Error in getting admins: ", error)
      toast.error(error.message)
    }
  }

  const handleSelectChange = async (admin) => {
    setSelectedAdmin(admin)
    try {
      const response = await axios.get(`${getBaseUrl()}/api/customer/getCustomersPerAdmin/${admin._id}`);
      console.log(response.data)
      if (response.data.success) {
        setTeamData(response.data.data)
      }
      else {
        setTeamData([])
        toast.error("Failed To retrieve customers data")
      }
    } catch (error) {
      setTeamData([])
      console.error("Error to get customers data: ", error)
      toast.error("Error to get customers data: " + error.message)
    }
  }

  useEffect(()=>{
    getAdmins();
  },[])

  return (
    <Box m="20px">
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <Header title="TEAM" subtitle="Managing the Team Members" />
        <Box minWidth={120} mt={isXs ? 2 : 0}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Admins</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedAdmin?.name}
                label="Admins"
                onChange={(e) => handleSelectChange(e.target.value)}
              >
                {
                  admins.length > 0 &&
                  admins.map((admin, index) => {
                    return <MenuItem key={index} value={admin}>{admin.username}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </Box>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
         <DataGrid 
          rows={teamData.map((item, index) => ({...item, id: index + 1}))} 
          columns={columns} 
          disableSelectionOnClick
        />
      </Box>
    </Box>
  );
};

export default Team;
