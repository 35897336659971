import React, { useState } from 'react';
import { Box, Button, TextField, DialogTitle, DialogContent, DialogActions } from "@mui/material";

const AddAdminForm = ({ onSubmit, onCancel }) => {
    const [formData, setFormData] = useState({
        phoneNumber: '',
        username: '',
        email: '',
        password:''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    const handleReset = () => {
        setFormData({
            phoneNumber: '',
            username: '',
            email: '',
            password: '',
        });
    };

    return (
        <Box>
            <DialogTitle>Add New Admin</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Phone Number"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="UserName"
                        name="username"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="E-Mail"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleReset} color="secondary">
                    Reset
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="contained">
                    Submit
                </Button>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Box>
    );
};

export default AddAdminForm;