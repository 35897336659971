import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import axios from "axios";
import getBaseUrl from "../../services/BaseUrl";
import { toast, ToastContainer } from "react-toastify";
import AddAdminForm from "./AddAdminForm";
import { ArrowBack, CancelOutlined, CheckCircleOutlineRounded, InfoOutlined, LockOutlined, Save, SaveAltOutlined, Settings } from "@mui/icons-material";

const Admins = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    const [admins, setAdmins] = useState([]);
    const [password, setPassword] = useState('')
    const [remark, setRemark] = useState('')
    const [openManageAccess, setOpenManageAccess] = useState(false);
    const [openConfirmAction, setOpenConfirmAction] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [selectedAccess, setSelectedAccess] = useState("");
    const [openAddAdminForm, setOpenAddAdminForm] = useState(false);
    const [openOtpDialog, setOpenOtpDialog] = useState(false);
    const [otp, setOtp] = useState('');
    const [newAdminData, setNewAdminData] = useState(null);

    const handleOpenAddAdminForm = () => {
        setOpenAddAdminForm(true);
    };

    const handleCloseAddAdminForm = () => {
        setOpenAddAdminForm(false);
    };

    const columns = [
        {
            field: "id",
            headerName: "S.No",
            width: 70
        },
        {
            field: "username",
            headerName: "Name",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "phoneNumber",
            headerName: "Phone Number",
            flex: 1,
        },
        {
            field: "businessName",
            headerName: "Business Name",
            flex: 1,
        },
        {
            field: "role",
            headerName: "Role",
            flex: 1,
        },
        {
            field: "paymentDate",
            headerName: "Payment Date",
            flex: 1,
            valueFormatter: (params) => {
                if (params.value) {
                    return new Date(params.value).toLocaleString();
                }
                return '';
            },
        },
        {
            field: "nextPaymentDate",
            headerName: "Next Payment Date",
            flex: 1,
            valueFormatter: (params) => {
                if (params.value) {
                    return new Date(params.value).toLocaleString();
                }
                return '';
            },
        },
        {
            field: "access",
            headerName: "Access",
            flex: 1,
            renderCell: ({ row: { access } }) => {
                return (
                    <Box
                        width="60%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        backgroundColor={
                            access === "granted"
                                ? colors.greenAccent[600]
                                : access === "denied"
                                    ? colors.redAccent[600]
                                    : colors.yellowAccent[600]
                        }
                        borderRadius="4px"
                    >
                        <Typography color={colors.grey[100]}>
                            {access.charAt(0).toUpperCase() + access.slice(1)}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "manageAccess",
            headerName: "Actions",
            flex: 1,
            renderCell: ({ row }) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenManageAccess(row)}
                >
                    <Settings />
                </Button>
            ),
        },
    ];

    const handleOpenManageAccess = (admin) => {
        setSelectedAdmin(admin);
        setSelectedAccess(admin.access)
        setPassword('')
        setRemark('')
        setOpenManageAccess(true);
    };

    const handleCloseManageAccess = () => {
        setOpenManageAccess(false);
        setSelectedAdmin(null);
    };

    const handleAccessChange = (access) => {
        setSelectedAccess(access);
        // console.log("Selected Access changed to:", access); // Add this line for debugging
    };

    const handleNextStep = (value) => {
        console.log(selectedAccess)
        if (value) {
            setOpenManageAccess(false);
            setOpenConfirmAction(true);
        }
        else {
            setOpenManageAccess(false);
        }
    }

    const handleConfirmAction = async (confirm) => {
        if (confirm === 'yes') {
            try {
                // API call to update access
                const response = await axios.put(`${getBaseUrl()}/api/users/updateaccess/${selectedAdmin._id}`, { access: selectedAccess, password, remark });
                if (response.data.success) {
                    // Update the admin in the local state
                    getAdmins()
                    toast.success("User updated successfully");
                    setPassword('')
                    setRemark('')
                } else {
                    toast.error(response.data.error);
                }
            } catch (error) {
                console.error("Error updating user: ", error);
                toast.error(error.message);
            }
        }
        if (confirm === 'back') {
            setOpenManageAccess(true);
            // setSelectedAccess("");
            setOpenConfirmAction(false);
            return;
        }
        setSelectedAdmin(null);
        setOpenConfirmAction(false);
        // setSelectedAccess("");
    };

    const getAdmins = async () => {
        try {
            const response = await axios.get(`${getBaseUrl()}/api/users`);
            if (response.data.success) {
                setAdmins(response.data.admins)
                console.log(response.data)
            }
            else {
                setAdmins([])
                toast.error(response.data.error)
            }
        } catch (error) {
            console.error("Error in getting admins: ", error)
            toast.error(error.message)
        }
    }

    const handleAddAdmin = async (newAdmin) => {
        try {
            const response = await axios.post(`${getBaseUrl()}/api/users/newuser`, newAdmin);
            if (response.data.success) {
                // Update the admin in the local state
                getAdmins()
                setNewAdminData(newAdmin);
                setOpenOtpDialog(true);
                // setAdmins([...admins, { ...newAdmin, id: admins.length + 1 }]);
                toast.success("Admin added successfully");
                handleCloseAddAdminForm();
            } else {
                toast.error(response.data.error);
            }
        } catch (error) {
            console.error("Error updating access: "+error);
            toast.error(error.response.data.error);
        }

    };

    const handleVerifyOtp = async () => {
        if (!otp) {
            toast.error("OTP is required");
            return;
        }

        try {
            const response = await axios.post(`${getBaseUrl()}/api/users/verifyemail`, { otp, ...newAdminData });
            if (response.data.success) {
                getAdmins();
                toast.success("admin verified successfully");
                setOpenOtpDialog(false);
            } else {
                toast.error(response.data.error);
            }
        } catch (error) {
            console.error("Error verifying OTP: ", error);
            toast.error(error.message);
        }
    };

    useEffect(() => {
        getAdmins();
    }, [])

    useEffect(() => {

    }, [selectedAccess, setSelectedAccess])

    return (
        <>
        <Box m="20px">
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <Header title="ADMINS" subtitle="Managing the Admin Members" />
                <Box minWidth={120} mt={isXs ? 2 : 0}>
                    <Button variant="contained" onClick={handleOpenAddAdminForm}>Add Admin</Button>
                </Box>
            </Box>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={admins.map((admin, index) => ({ ...admin, id: index + 1 }))}
                    columns={columns}
                    disableSelectionOnClick
                />
            </Box>

            <Dialog open={openManageAccess} onClose={handleCloseManageAccess}>
                <DialogTitle sx={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    textAlign: "center"
                }}>Manage Access</DialogTitle>
                <DialogContent sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    textAlign: "center"
                }}>
                    <Typography>Are you sure you want to change the Properties for <Box component="span" sx={{
                        color: "crimson",
                        fontWeight: "bold",
                        fontSize: "16px"
                    }}>{selectedAdmin?.name}</Box> ?</Typography>
                </DialogContent>
                <DialogContent>
                    <Box display="flex" justifyContent="space-between" mt={2} sx={{
                        padding: "0 5vw",
                        gap: "2vw",
                    }}>
                        <Button
                            variant="contained"
                            onClick={() => handleAccessChange("granted")}
                            fullWidth
                            sx={{
                                backgroundColor: selectedAccess === "granted" ? "success.main" : undefined,
                                '&:hover': {
                                    backgroundColor: selectedAccess === "granted" ? "success.dark" : undefined,
                                },
                                padding: "10px",
                                display: "flex",
                                flexDirection: "column"
                            }}
                        >
                            <CheckCircleOutlineRounded sx={{
                                fontSize: "3rem"
                            }} />
                            <Typography >Active</Typography>
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => handleAccessChange("hold")}
                            fullWidth
                            sx={{
                                backgroundColor: selectedAccess === "hold" ? "warning.main" : undefined,
                                '&:hover': {
                                    backgroundColor: selectedAccess === "hold" ? "warning.dark" : undefined,
                                },
                                padding: "10px",
                                display: "flex",
                                flexDirection: "column"
                            }}
                        >
                            <InfoOutlined sx={{
                                fontSize: "3rem"
                            }} />
                            <Typography >Suspend</Typography>
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => handleAccessChange("denied")}
                            fullWidth
                            sx={{
                                backgroundColor: selectedAccess === "denied" ? "error.main" : undefined,
                                '&:hover': {
                                    backgroundColor: selectedAccess === "denied" ? "error.dark" : undefined,
                                },
                                padding: "10px",
                                display: "flex",
                                flexDirection: "column"
                            }}
                        >
                            <LockOutlined sx={{
                                fontSize: "3rem"
                            }} />
                            <Typography>Lock</Typography>
                        </Button>
                    </Box>
                    <Box sx={{ marginBottom: 2, marginTop: 2 }}>
                        <TextField
                            label="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            fullWidth
                        />
                    </Box>
                    <Box sx={{ marginBottom: 2 }}>
                        <TextField
                            label="Remark"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                            fullWidth
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{
                    padding: "20px 2vw 20px ",
                    gap: "2vw"
                }}>
                    <Button size="large" variant="contained" onClick={() => handleNextStep(true)} color="secondary" startIcon={<SaveAltOutlined />} sx={{ fontWeight: "bold" }}>Save</Button>
                    <Button size="large" variant="contained" onClick={() => handleNextStep(false)} color="info" startIcon={<CancelOutlined />} sx={{ fontWeight: "bold" }}>Cancel</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openConfirmAction} onClose={() => handleConfirmAction('cancel')}>
                <DialogTitle sx={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    textAlign: "center"
                }}>Confirm Action</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to change the access to {selectedAccess}  {password.length > 0 && 'and password'}?</Typography>
                </DialogContent>
                <DialogActions sx={{
                    padding: "20px 2vw 20px ",
                    gap: "2vw"
                }}>
                    <Button variant="contained" size="large" onClick={() => handleConfirmAction('yes')} color="primary" startIcon={<CheckCircleOutlineRounded />}>Yes</Button>
                    <Button variant="contained" size="large" onClick={() => handleConfirmAction('no')} color="primary" startIcon={<CancelOutlined />} >No</Button>
                    <Button variant="contained" size="large" onClick={() => handleConfirmAction('back')} color="primary" startIcon={<ArrowBack />}>Back</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAddAdminForm} onClose={handleCloseAddAdminForm}>
                <AddAdminForm onSubmit={handleAddAdmin} onCancel={handleCloseAddAdminForm} />
            </Dialog>

            <Dialog open={openOtpDialog} onClose={() => setOpenOtpDialog(false)}>
                <DialogTitle>Verify OTP</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="otp"
                        label="OTP"
                        type="number"
                        fullWidth
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenOtpDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleVerifyOtp} color="primary">
                        Verify
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
        <ToastContainer/>
        </>
    );
};

export default Admins;