import React, { useEffect, useState } from 'react';
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, Typography, useTheme, useMediaQuery, Grid } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import { KeyboardArrowUpOutlined } from "@mui/icons-material";
import Calculator from '../Calculator/calculator';
import axios from 'axios';
import getBaseUrl from '../../services/BaseUrl';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { growthPulsesPredefinedData } from './customersDashBoard';

const revenueInflowData = [
  {
    icon:
      <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg" style={{ color: "rgb(255, 255, 255)" }}><path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514Z"></path><path d="M12.096 6.223A4.92 4.92 0 0 0 13 5.698V7c0 .289-.213.654-.753 1.007a4.493 4.493 0 0 1 1.753.25V4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.525 4.525 0 0 1-.813-.927C8.5 14.992 8.252 15 8 15c-1.464 0-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13h.027a4.552 4.552 0 0 1 0-1H8c-1.464 0-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10c.262 0 .52-.008.774-.024a4.525 4.525 0 0 1 1.102-1.132C9.298 8.944 8.666 9 8 9c-1.464 0-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777ZM3 4c0-.374.356-.875 1.318-1.313C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4Z"></path></svg>,
    cash: '$0.00',
    heading: 'Total Cash Collected',
    bg: '#9EE8F4'
  },
  {
    icon:
      <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg" style={{ color: "rgb(255, 255, 255)" }}><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M15 11v.01"></path><path d="M5.173 8.378a3 3 0 1 1 4.656 -1.377"></path><path d="M16 4v3.803a6.019 6.019 0 0 1 2.658 3.197h1.341a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-1.342c-.336 .95 -.907 1.8 -1.658 2.473v2.027a1.5 1.5 0 0 1 -3 0v-.583a6.04 6.04 0 0 1 -1 .083h-4a6.04 6.04 0 0 1 -1 -.083v.583a1.5 1.5 0 0 1 -3 0v-2l0 -.027a6 6 0 0 1 4 -10.473h2.5l4.5 -3h0z"></path></svg>,
    cash: '$0.00',
    heading: 'Total Collection In Bank',
    bg: '#F7ACAA'
  },
  {
    icon:
      <svg stroke="currentColor" fill="none" strokeWidth="1.5" viewBox="0 0 24 24" aria-hidden="true" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg" style={{ color: "rgb(255, 255, 255)" }}><path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"></path></svg>,
    cash: '$0.00',
    heading: 'Unavoidable GST Payables',
    bg: "#B8B3F5"
  },
  {
    icon:
      <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg" style={{ color: "rgb(255, 255, 255)" }}><path fillRule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"></path><path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"></path><path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"></path><path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"></path></svg>,
    cash: '$0.00',
    heading: 'Total Cash Balance (As on)',
    bg: "#A2E3B8"
  },
  {
    icon:
      <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg" style={{ color: "rgb(255, 255, 255)" }}><path d="M894 462c30.9 0 43.8-39.7 18.7-58L530.8 126.2a31.81 31.81 0 0 0-37.6 0L111.3 404c-25.1 18.2-12.2 58 18.8 58H192v374h-72c-4.4 0-8 3.6-8 8v52c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-52c0-4.4-3.6-8-8-8h-72V462h62zM381 836H264V462h117v374zm189 0H453V462h117v374zm190 0H642V462h118v374z"></path></svg>,
    cash: '$0.00',
    heading: 'Total Bank Balance (As on)',
    bg: "#95B9F4"
  },
  {
    icon:
      <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 384 512" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg" style={{ color: "rgb(255, 255, 255)" }}><path d="M14 2.2C22.5-1.7 32.5-.3 39.6 5.8L80 40.4 120.4 5.8c9-7.7 22.3-7.7 31.2 0L192 40.4 232.4 5.8c9-7.7 22.3-7.7 31.2 0L304 40.4 344.4 5.8c7.1-6.1 17.1-7.5 25.6-3.6s14 12.4 14 21.8V488c0 9.4-5.5 17.9-14 21.8s-18.5 2.5-25.6-3.6L304 471.6l-40.4 34.6c-9 7.7-22.3 7.7-31.2 0L192 471.6l-40.4 34.6c-9 7.7-22.3 7.7-31.2 0L80 471.6 39.6 506.2c-7.1 6.1-17.1 7.5-25.6 3.6S0 497.4 0 488V24C0 14.6 5.5 6.1 14 2.2zM96 144c-8.8 0-16 7.2-16 16s7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96zM80 352c0 8.8 7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96c-8.8 0-16 7.2-16 16zM96 240c-8.8 0-16 7.2-16 16s7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96z"></path></svg>,
    cash: '$0.00',
    heading: 'Unavoidable Other Tax Payables',
    bg: '#FDD0A2'
  }
]

const Dashboard = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  // const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  // const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const colors = tokens(theme.palette.mode);

  const [selectValue, setSelectValue] = useState('');
  const [products, setProducts] = useState([]);
  const [growthPulses, setGrowthPulses] = useState([]);

  const getProducts = async () => {
    // console.log(`${getBaseUrl()}/api/users`)
    try {
      const response = await axios.get(`${getBaseUrl()}/api/products`);
      if (response.data.success) {
        // toast.success("Products Get Successfully")
        setProducts(response.data.products)
      }
      else {
        setProducts([])
        toast.error(response.data.error)
      }
    } catch (error) {
      setProducts([{
        _id: "123450",
        name: "xeroBook"
      }])
      console.error("Error in getting products: ", error)
      // toast.error(error.message)
    }
  }

  const getSummary = async () => {
    try {
      const response = await axios.get(`${getBaseUrl()}/api/customer/getAllCustomersIncomeSummary`);
      // console.log(response.data)
      if (response.data.success) {
        settingUpGrowthPulses(response.data.data)
        // setGrowthPulses()  
      }
      else {
        setGrowthPulses([])
        toast.error("Failed To retrieve growth pulses data")
      }
    } catch (error) {
      console.error("Error to get growth pulses data: ", error)
      toast.error("Error to get growth pulses data: " + error.message)
    }
  }

  const settingUpGrowthPulses = async (data) => {
    let newData = data.map((item, index) => {
      return { ...item, ...growthPulsesPredefinedData[index] };
    });
    setGrowthPulses(newData);
  }

  useEffect(() => {
    getSummary()
    getProducts()
  }, [])

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" flexDirection={isXs ? "column" : "row"} justifyContent="space-between" alignItems={isXs ? "stretch" : "center"} mb="20px" >
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

        <Box minWidth={120} mt={isXs ? 2 : 0}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Products</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectValue}
              label="Products"
              onChange={(e) => setSelectValue(e.target.value)}
            >
              {
                products.length > 0 &&
                products.map((product, index) => {
                  return <MenuItem key={index} value={product._id}>{product.name}</MenuItem>
                })
              }
            </Select>
          </FormControl>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Grid container spacing={3}>
        {/* ROW 1 */}
        {growthPulses.map((stat, index) => (
          <Grid item xs={12} md={6} lg={3} key={index}>
            <Box backgroundColor={stat.color} height="100%" p={2}>
              <StatBox
                title={stat.title}
                subtitle={stat.subtitle}
                progress={stat.progress}
                increase={stat.increase}
                icon={stat.icon}
                color={stat.color}
              />
            </Box>
          </Grid>
        ))}

        {/* ROW 2 */}
        <Grid item xs={12}>
          <Box backgroundColor={colors.primary[400]} p={3} sx={{
            border: "1px solid black",
            borderRadius: "10px"
          }}>
            <Typography variant="h4" mb={2}>Revenue Projections</Typography>
            <Grid container spacing={3}>
              {[
                { title: "Total Receivable Amount", value: "$0.00" },
                { title: "Total Payable Amount", value: "$0.00" },
                { title: "Long Term Pending Receipts", value: "$0.00" }
              ].map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box display="flex" alignItems="center" border="1px solid" borderColor={colors.grey[100]} borderRadius="4px" p={2}>
                    <Box flex={1}>
                      <Box>
                        <KeyboardArrowUpOutlined sx={{ fontSize: 40, color: colors.greenAccent[600] }} />
                      </Box>
                      <Typography variant="h5" color={colors.greenAccent[600]}>100%</Typography>
                    </Box>
                    <Box flex={2}>
                      <Typography variant="h5">{item.value}</Typography>
                      <Typography variant="body1">{item.title}</Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>

        {/* ROW 3 */}
        <Grid item xs={12}>
          <Box backgroundColor={colors.primary[400]} p={3} sx={{
            border: "1px solid black",
            borderRadius: '10px'
          }}>
            <Typography variant="h4" mb={2}>Revenue Inflow</Typography>
            <Grid container spacing={3}>
              {revenueInflowData.map((data, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box display="flex" alignItems="center" p={2}>
                    <Box mr={2} sx={{
                      backgroundColor: data.bg,
                      height: '4rem',
                      width: '4rem',
                      borderRadius: '50%',
                      display: "flex",
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                      {data.icon}
                    </Box>
                    <Box>
                      <Typography variant="h4" sx={{
                        color: data.bg,
                        fontWeight: 'bold'
                      }}>{data.cash}</Typography>
                      <Typography variant="body1" sx={{
                        fontWeight: 'bold'
                      }}>{data.heading}</Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>

        {/* ROW 4 */}
        <Grid item xs={12} md={4}>
          <Box backgroundColor={colors.primary[400]} p={3}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Box>
                <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
                  Revenue Generated
                </Typography>
                <Typography variant="h3" fontWeight="bold" color={colors.greenAccent[500]}>
                  $59,342.32
                </Typography>
              </Box>
              <IconButton>
                <DownloadOutlinedIcon sx={{ fontSize: "26px", color: colors.greenAccent[500] }} />
              </IconButton>
            </Box>
            <Box height="300px">
              <LineChart isDashboard={true} />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box backgroundColor={colors.primary[400]} p={3} height="100%" overflow="auto">
            <Typography variant="h5" fontWeight="600" mb={2}>
              Recent Transactions
            </Typography>
            {mockTransactions.map((transaction, i) => (
              <Box
                key={`${transaction.txId}-${i}`}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={`1px solid ${colors.primary[500]}`}
                p={2}
              >
                <Box>
                  <Typography color={colors.greenAccent[500]} variant="body1" fontWeight="600">
                    {transaction.txId}
                  </Typography>
                  <Typography color={colors.grey[100]}>{transaction.user}</Typography>
                </Box>
                <Box color={colors.grey[100]}>{transaction.date}</Box>
                <Box backgroundColor={colors.greenAccent[500]} p={1} borderRadius="4px">
                  ${transaction.cost}
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>

        {/* <Grid item xs={12} md={4}>
          <Calculator />
        </Grid> */}
        {/* ROW 5 */}
        <Grid item xs={12} sm={6} md={4}>
          <Box backgroundColor={colors.primary[400]} p={3} height="100%">
            <Typography variant="h5" fontWeight="600" mb={2}>
              Campaign
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="center">
              <ProgressCircle size="125" />
              <Typography variant="h5" color={colors.greenAccent[500]} mt={2}>
                $48,352 revenue generated
              </Typography>
              <Typography>Includes extra misc expenditures and costs</Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Box backgroundColor={colors.primary[400]} p={3} height="100%">
            <Typography variant="h5" fontWeight="600" mb={2}>
              Sales Quantity
            </Typography>
            <Box height="250px">
              <BarChart isDashboard={true} />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box backgroundColor={colors.primary[400]} p={3} height="100%">
            <Typography variant="h5" fontWeight="600" mb={2}>
              Geography Based Traffic
            </Typography>
            <Box height="200px">
              <GeographyChart isDashboard={true} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;