import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import CustomersDashboard from "./scenes/dashboard/customersDashBoard";
import Admins from './scenes/admins';
import Login from './scenes/login/login.jsx'; // Assuming you have a Login component
import axios from 'axios';
import getBaseUrl from './services/BaseUrl.js';
import { ToastContainer } from 'react-toastify';

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate()
  
  useEffect(() => {

    const handleLogout = async () => {

      try {
        const res = await axios.get(`${getBaseUrl()}/api/admin/logout`)
        if(res.data.success){
          localStorage.removeItem('authentication_token_admin');
          localStorage.removeItem('currentAdmin');
          navigate(0)
        }
        else{
          alert(res.data.error)
        }
      } catch (error) {
        console.error("error in logout admin")
      }
  
    }
    
    const verifyToken = async () => {
      const token = localStorage.getItem('authentication_token_admin');
      if (token) {
        try {
          axios.defaults.withCredentials = true;
          const response = await axios.get(getBaseUrl() + '/api/admin/verify');
          
          if (response.data.success) {
            setIsAuthenticated(true);
          } else {
            handleLogout()
            setIsAuthenticated(false);
          }
        } catch (error) {
          console.error("Error verifying token:", error);
          handleLogout()
          setIsAuthenticated(false);
        }
      } else {
        handleLogout()
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };

    verifyToken();

  }, []);

  const ProtectedRoute = ({ children }) => {
    // console.log(isAuthenticated,children)
    if (isLoading) {
      return <div>Loading...</div>;
    }
    if (!isAuthenticated) {
      return <Navigate to="/login" />;
    }
    return children;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {isAuthenticated && <Sidebar isSidebar={isSidebar} />}
          <main className="content" style={{ height: '100vh', overflowY: 'auto' }}>
            {isAuthenticated && <Topbar setIsSidebar={setIsSidebar} />}
            <Routes>
              <Route path="/login" element={
                isAuthenticated ? <Navigate to="/" /> : <Login setIsAuthenticated={setIsAuthenticated} />
              } />
              <Route path="/" element={
                <ProtectedRoute>
                  <div style={{ height: '100%', overflowY: 'auto', backgroundColor: '#f8f8f8' }}><Dashboard /></div>
                </ProtectedRoute>
              } />
              <Route path="/customers" element={
                <ProtectedRoute>
                  <div style={{ height: '100%', overflowY: 'auto', backgroundColor: '#f8f8f8' }}><CustomersDashboard /></div>
                </ProtectedRoute>
              } />
              <Route path="/team" element={
                <ProtectedRoute>
                  <div style={{ height: '100%', overflowY: 'auto', backgroundColor: '#f8f8f8' }}><Team /></div>
                </ProtectedRoute>
              } />
              <Route path="/admins" element={
                <ProtectedRoute>
                  <div style={{ height: '100%', overflowY: 'auto', backgroundColor: '#f8f8f8' }}><Admins /></div>
                </ProtectedRoute>
              } />
              <Route path="/contacts" element={
                <ProtectedRoute>
                  <div style={{ height: '100%', overflowY: 'auto' }}><Contacts /></div>
                </ProtectedRoute>
              } />
              <Route path="/invoices" element={
                <ProtectedRoute>
                  <div style={{ height: '100%', overflowY: 'auto' }}><Invoices /></div>
                </ProtectedRoute>
              } />
              <Route path="/form" element={
                <ProtectedRoute>
                  <div style={{ height: '100%', overflowY: 'auto' }}><Form /></div>
                </ProtectedRoute>
              } />
              <Route path="/bar" element={
                <ProtectedRoute>
                  <div style={{ height: '100%', overflowY: 'auto' }}><Bar /></div>
                </ProtectedRoute>
              } />
              <Route path="/pie" element={
                <ProtectedRoute>
                  <div style={{ height: '100%', overflowY: 'auto' }}><Pie /></div>
                </ProtectedRoute>
              } />
              <Route path="/line" element={
                <ProtectedRoute>
                  <div style={{ height: '100%', overflowY: 'auto' }}><Line /></div>
                </ProtectedRoute>
              } />
              <Route path="/faq" element={
                <ProtectedRoute>
                  <div style={{ height: '100%', overflowY: 'auto' }}><FAQ /></div>
                </ProtectedRoute>
              } />
              <Route path="/calendar" element={
                <ProtectedRoute>
                  <div style={{ height: '100%', overflowY: 'auto' }}><Calendar /></div>
                </ProtectedRoute>
              } />
              <Route path="/geography" element={
                <ProtectedRoute>
                  <div style={{ height: '100%', overflowY: 'auto' }}><Geography /></div>
                </ProtectedRoute>
              } />
            </Routes>
          </main>
        </div>
        <ToastContainer />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;