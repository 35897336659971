// import { handlerToGetSelectedAdmin } from "../Actions/action";
import { SELECTED_ADMIN, SETSELECTEDADMIN } from "../reduxConstant";

const initailState = {
    selectedAdmin: {},
}

const adminReducer = (state = initailState, action) => {
    switch (action.type) {
        case SELECTED_ADMIN: return {
            ...state,
            selectedAdmin: state.selectedAdmin 
        }
        case SETSELECTEDADMIN:
            const admin = action.payload;
            return {
                ...state, selectedAdmin: {
                    ...admin
                }
            }
        default: return state
    }
}

export default adminReducer;